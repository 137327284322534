<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Processamento de Treinamentos</h1>
      <div class="container-btns" v-if="perfil != 5 && perfil != null">
        <button class="btn btn-laranja" @click="adicionarTreinamentos">Processar Treinamentos</button>
      </div>
      <form @submit.prevent class="formulario-container">
        <div class="d-md-flex">
          <div class="formulario-item">
            <label class="formulario-item-label">Tipo de treinamento</label>
            <multiselect v-model="form.tipoTreinamento" label="descricao" track-by="descricao"
              :options="tipoTreinamento" :searchable="false" :multiple="false" placeholder="Tipo de treinamento"
              select-label="Selecionar" deselect-label="Remover" selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Nome do Treinamento</label>
            <multiselect v-model="form.nome" label="descricao" track-by="descricao" :options="nome" :searchable="true"
              :multiple="false" placeholder="Nome do Treinamento" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">CPF</label>
            <input type="text" v-model="form.cpf" v-mask="'###.###.###-##'" placeholder="CPF" />
          </div>

        </div>
        <div class="d-md-flex">

          <div class="formulario-item">
            <label class="formulario-item-label">Data de conclusão</label>
            <datepicker v-model="form.dataConclusao" :language="ptBR" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Mês</label>
            <multiselect v-model="form.mes" label="nome" track-by="id" :options="opcoesMeses" :searchable="false"
              :multiple="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Ano</label>
            <input type="text" v-model="form.ano" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Tipo</label>
            <multiselect label="descricao" track-by="id" v-model="form.idTipoProcessamento" :options="tipo"
              :searchable="false" :allow-empty="false" select-label="Selecionar" deselect-label="Remover"
              placeholder="Clique para selecionar o tipo" selected-label="Selecionado" />
          </div>
        </div>
        <div class="container-btns">
          <button class="btn btn-cancelar" @click="limparDados">
            Limpar
          </button>
          <button class="btn btn-laranja" @click="listar(1, true)">
            Filtrar
          </button>
        </div>
      </form>

      <transition name="fade-left" mode="out-in">

        <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
          <p>Sem dados</p>
        </div>

      </transition>

      <div v-if="treinamentos.length > 0" class="tabela-informacoes" key="Lista">
        <div class="paginacao">
          <button :disabled="paginaBusca == 1" @click="pagAnterior">Anterior</button>
          <p>{{ paginaBusca }}</p>
          <button :disabled="ultimoItem" @click="proxPagina">Próxima</button>
        </div>

        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-cpf">
            <p>TIPO</p>
          </div>
          <div class="tabela-linha-data">
            <p>DATA CONCLUSÃO</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>CPF</p>
          </div>
          <div class="tabela-linha-treinamento">
            <p>TIPO TREINAMENTO</p>
          </div>
          <div class="tabela-linha-treinamento">
            <p>NOME TREINAMENTO</p>
          </div>
          <div class="tabela-linha-data">
            <p>PERCENTUAL DE ACERTO </p>
          </div>
          <div class="tabela-linha-data">
            <p>STATUS TREINAMENTO</p>
          </div>
          <div class="tabela-linha-data">
            <p>PONTUAÇÃO TREINAMENTO</p>
          </div>
          <div class="tabela-linha-id">
            <p>MÊS VIGENTE</p>
          </div>
          <div class="tabela-linha-id">
            <p>ANO VIGENTE</p>
          </div>
          <!-- <div class="tabela-linha-btns"></div> -->
        </div>
        <div v-for="(treinamento, index) in treinamentos" :key="index" class="tabela-linha tabela-linha-informacoes">
          <!-- v-for="item in dadosTabela" :key="item.id" -->
          <div class="tabela-linha-cpf">
            <p>{{ treinamento.tipoProcessamentoId == '1' ? "Fechamento" : "Parcial" }}</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ formatarData(treinamento.dataConclusao) }}</p>
          </div>
          <div class="tabela-linha-cpf">
            <p>{{ treinamento.cpfParticipante }}</p>
          </div>
          <div class="tabela-linha-treinamento">
            <p>{{ treinamento.tipoTreinamento }}</p>
          </div>
          <div class="tabela-linha-treinamento">
            <p>{{ treinamento.nomeTreinamento }}</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ treinamento.percentualAcerto }}%</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ treinamento.status }}</p>
          </div>
          <div class="tabela-linha-data">
            <p>{{ treinamento.pontuacao }} Pontos</p>
          </div>
          <div class="tabela-linha-id">
            <p>{{ treinamento.mesVigente }}</p>
          </div>
          <div class="tabela-linha-id">
            <p>{{ treinamento.anoVigente }}</p>
          </div>
          <!-- <div class="tabela-linha-btns">
            <div class="tabela-linha-btns-editar" @click="editarPromocoes(item.id)">
              <img src="@/assets/images/icones/editar.svg" alt="Editar" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import Multiselect from "vue-multiselect";
import { listarTreinamentos } from "@/services/apuracao-treinamentos";
import { listarTipoTreinamento, listarNomeTreinamento } from "@/services/banner-treinamento";
import { opcoesMeses } from '@/utils/json'
import moment from "moment";

export default {
  name: "TreinamentosListar",
  components: {
    Datepicker,
    Multiselect
  },
  data() {
    return {
      opcoesMeses: opcoesMeses.mes,
      semDados: true,
      paginaBusca: 1,
      ptBR: ptBR,
      tipoTreinamento: [],
      nome: [],
      treinamentos: [],
      tipo: [
        { id: 1, descricao: 'Fechamento' },
        { id: 2, descricao: 'Parcial' }
      ],
      form: {
        nome: {},
        mes: "",
        tipoTreinamento: {},
        cpf: "",
        dataConclusao: "",
        ano: "",
        idTipoProcessamento: []
      },
      perfil: null
    };
  },
  computed: {
    listaPromocoesCarregado() {
      return this.$store.state.promocoes.listaPromocoesCarregado;
    },
    listaPromocoes() {
      return this.$store.state.promocoes.listaPromocoes;
    },
  },
  created() {
    this.listar(1, false);
    this.listarTipoDeTreinamento();
    this.nomeTreinamento();
    this.perfil = localStorage.getItem('perfil');
  },
  methods: {
    listarTipoDeTreinamento() {
      listarTipoTreinamento().then(resp => {
        this.tipoTreinamento = resp.data
      })
    },
    nomeTreinamento() {
      listarNomeTreinamento().then(resp => {
        this.nome = resp.data
      })
    },
    adicionarTreinamentos() {
      this.$router.push("/treinamentos/adicionar");
    },
    limparDados() {
      this.form = {
        nome: {},
        mes: "",
        tipoTreinamento: {},
        cpf: "",
        dataConclusao: "",
        ano: "",
        idTipoProcessamento: []
      }

      this.listar(1, false)

    },
    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    listar(pag, tipoFiltro) {
      let data = {};
      if (tipoFiltro) {
        data = {
          nomeUsuario: "",
          cpf: this.form.cpf.replaceAll(".", "").replace("-", ""),
          cnpj: "",
          dataConclusao: this.form.dataConclusao ? moment(this.form.dataConclusao).format("YYYY-MM-DD") : "",
          dataInicio: "",
          dataFim: "",
          idTipoProcessamento: this.form.idTipoProcessamento.id && this.form.idTipoProcessamento.id,
          mesVigente: this.form.mes && this.form.mes.id ? this.form.mes.id : 0,
          anoVigente: this.form.ano ? Number(this.form.ano) : 0,
          idTipo: this.form.tipoTreinamento && this.form.tipoTreinamento.id ? this.form.tipoTreinamento.id : 0,
          idNome: this.form.nome && this.form.nome.id ? this.form.nome.id : 0,
          pagina: this.paginaBusca,
          qtdPorPagina: 15,
        }
      } else {
        data = {
          nomeUsuario: "",
          cpf: "",
          cnpj: "",
          dataConclusao: "",
          dataInicio: "",
          dataFim: "",
          mesVigente: 0,
          anoVigente: 0,
          idTipo: 0,
          idNome: 0,
          pagina: this.paginaBusca,
          qtdPorPagina: 15,
        }
      }

      listarTreinamentos(data).then(resp => {
        if (resp.data.lista.length == 0) {
          this.treinamentos = [];
          if (pag && pag > 1) {
            this.ultimoItem = true;
          } else {
            this.semDados = true;
          }
        } else {
          resp.data.lista.length < 15 ? (this.ultimoItem = true) : (this.ultimoItem = false);
          if (pag) {
            this.paginaBusca = pag;
          }
          this.semDados = false;
          this.treinamentos = resp.data.lista;
        }
      })
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      this.listar(pag);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      this.listar(pag);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />